import { dataProviderRegister } from "@/api/dataProviderRegister";

const register = {
  namespaced: true,
  state: () => ({}),

  mutations: {
    setRegister(state, payload) {
      (state.FirstName = payload.FirstName),
        (state.LastName = payload.LastName),
        (state.CompanyName = payload.CompanyName),
        (state.JobTitle = payload.JobTitle),
        (state.CompanyAddress = payload.CompanyAddress),
        (state.City = payload.City),
        (state.State = payload.State),
        (state.PostalCode = payload.PostalCode),
        (state.PhoneNumber = payload.PhoneNumber),
        (state.FaxNumber = payload.FaxNumber),
        (state.EmailAddress = payload.EmailAddress),
        (state.confirmemail = payload.confirmemail),
        (state.Password = payload.Password),
        (state.confirmpassword = payload.confirmpassword),
        (state.MCNumber = payload.MCNumber),
        (state.Subscribe = payload.Subscribe);
    },
  },

  actions: {
    async TryDoRegister({ commit }, form) {
      try {

        let response = await dataProviderRegister.register(form);

        let payload = {
          FirstName: response.FirstName,
          LastName: response.LastName,
          CompanyName: response.CompanyName,
          JobTitle: response.JobTitle,
          CompanyAddress: response.CompanyAddress,
          City: response.City,
          State: response.State,
          PostalCode: response.PostalCode,
          PhoneNumber: response.PhoneNumber,
          FaxNumber: response.FaxNumber,
          EmailAddress: response.EmailAddress,
          confirmemail: response.confirmemail,
          Password: response.Password,
          confirmpassword: response.confirmpassword,
          MCNumber: response.MCNumber,
          Subscribe: response.Subscribe,
        };
        commit("setRegister", payload);

        return response;
      } catch (err) {
        if (err.response.status === 403) {
          throw { combinemessage: err.response.data, status: 403 };
        }
        if (err.response.status === 400) {
          throw { message: err.response.data, status: 400 };
        }
      }
    },
  },
  getter: {},
};

export default register;
