<template>
  <header class="p-3 lg:p-0">
    <div class="container mx-auto">
      <div class="headerbar">
        <router-link to="/Home"
          ><img src="@/assets/images/logo.png" alt="" class="logo"
        /></router-link>

        <ul class="nav-list lg:inline-flex hidden">
          <li>
            <router-link
              to="/"
              v-bind:class="{
                'router-link-active router-link-exact-active':
                  $route.path == '/ftl' ||
                  $route.path == '/ltl' ||
                  $route.path == '/factoring-company',
              }"
              >Payments</router-link
            >
          </li>
          <li>
            <Menu as="div" class="relative lg:inline-block text-left">
              <MenuButton class="flex items-center">
                Loads
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-40
                    rounded-md
                    shadow-lg
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                    z-50
                  "
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="my-loads"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >My Loads</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/available-loads"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >Available Loads</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/upload-document"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >Upload Document</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </li>
          <li><router-link to="/contact">Contact Us</router-link></li>
        </ul>

        <div class="flex items-center">
          <div
            class="relative lg:inline-block text-left"
            v-if="isAuthed == true"
          >
            <Menu as="div">
              <div>
                <MenuButton class="user-holder">
                  <div class="user-wrapper" @click="toggleDropdown">
                    <div class="user">{{ userInitial }}</div>
                  </div>
                  <svg
                    class="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-40
                    rounded-md
                    shadow-lg
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/my-account"
                        :class="[
                          active
                            ? 'cursor bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >My Account</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        @click="logOut"
                        :class="[
                          active
                            ? 'cursor bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >Sign Out</a
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <router-link to="/login" v-if="isAuthed == false" class="main-btn"
            >Sign In</router-link
          >
          <button
            type="button"
            class="
              sm:inline-flex
              lg:hidden
              items-center
              justify-center
              p-4
              text-gray-400
            "
            aria-controls="mobile-menu"
            aria-expanded="false"
            @click="toggleMenu"
          >
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path></svg
            ><svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div></div>
      <div class="mobile-menu" :class="{ openmenu: showMenu }" ref="mobilemenu">
        <ul class="nav-list lg:hidden block">
          <li>
            <router-link
              to="/"
              @click="closeMenu"
              v-bind:class="{
                'router-link-active router-link-exact-active':
                  $route.path == '/ftl' ||
                  $route.path == '/ltl' ||
                  $route.path == '/factoring-company',
              }"
              >Payments</router-link
            >
          </li>
          <li>
            <Menu as="div" class="relative lg:inline-block text-left">
              <MenuButton class="flex items-center">
                Loads
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </MenuButton>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-40
                    rounded-md
                    shadow-lg
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="my-loads"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >My Loads</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/available-loads"
                        @click="closeMenu"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >Available Loads</router-link
                      >
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/upload-document"
                        :class="[
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >Upload Document</router-link
                      >
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </li>
          <li>
            <router-link to="/contact" @click="closeMenu"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  data() {
    return {
      email: this.$store.state.login.EmailAddress,
      isActive: false,
      showMenu: false,
    };
  },
  methods: {
    logOut() {
      this.isAuthed = false;
      this.$store.commit("login/clearLogin");
      this.$router.push("/login");
      return;
    },
    toggleDropdown() {
      this.isActive = !this.isActive;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  computed: {
    isAuthed: function () {
      return this.$store.state.login.authed == true ? true : false;
    },
    userInitial() {
      return this.email.charAt(0);
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  background: #fff;
  .headerbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 60px;
      display: inline-block;
    }
    .nav-list {
      li {
        a {
          padding: 20px 20px;
          line-height: 30px;
          position: relative;
          display: block;
          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 4px;
            background: #65b0ba;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform 0.1s;
          }
          &.router-link-active {
            &:before {
              transform: scaleX(1);
            }
          }
        }
        .py-1 {
          a {
            padding: 5px 10px;
          }
        }
        button {
          padding: 20px 20px;
          line-height: 30px;
          position: relative;
          display: block;
          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 4px;
            background: #65b0ba;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            transition: transform 0.1s;
          }
          &.router-link-active {
            &:before {
              transform: scaleX(1);
            }
          }
        }
      }
    }

    .user-holder {
      display: flex;
      align-items: center;
      cursor: pointer;
      .user-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fdf7ef;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
    }
  }
  .dnone {
    display: none;
  }
}
.cursor {
  cursor: pointer;
}
header {
  .headerbar {
    display: flex;
  }
}
.flex {
  display: flex !important;
}
.mobile-menu {
  display: none;
  &.openmenu {
    display: block;
    .nav-list {
      display: block;
      li {
        a {
          padding: 10px 20px;
        }
        button {
          padding: 10px 20px;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
  .nav-list {
    background: #fafafa;
    border-radius: 6px;
    margin-top: 10px;
    li {
      a {
        display: block;
      }
    }
  }
}
@media (min-width: 992px) {
  .mobile-menu {
    display: block;
  }
}
</style>
