import { dataProviderUpload } from "@/api/dataProviderUpload";

const Upload = {
  namespaced: true,
  state: () => ({
    response: "",
    LoadID: "",
    MCUSNumber: "",
  }),

  mutations: {
    setUpload(state, payload) {
      state.response = payload;
      state.LoadID = payload.LoadID;
      state.MCUSNumber = payload.MCUSNumber;
    },
  },

  actions: {
    async TryDoUpload({ commit }, form) {
      try {
        let response = await dataProviderUpload.upload(form);
        let payload = {
          IsSuccessful: response.IsSuccessful,
          Locations: response.Locations,
          PaymentInfo: response.PaymentInfo,
          ResponseText: response.ResponseText,
          LoadID: form.LoadID,
          MCUSNumber: form.MCUSNumber,
        };
        commit("setUpload", payload);

        return response;
      } catch (err) {
        if (err.response.status === 403) {
          throw { combinemessage: err.response.data, status: 403 };
        }
        if (err.response.status === 400) {
          throw { message: err.response.data, status: 400 };
        }
      }
    },
  },
  getters: {
    ResponseText(state) {
      return state.ResponseText;
    },
  },
};

export default Upload;
