<template>
  <footer>
    <div class="container mx-auto">
      <p class="copyright">© 2021 Logistic Dynamics, LLC</p>
    </div>
  </footer>
</template>
<style scoped>
footer {
  margin-top: auto;
}
</style>
