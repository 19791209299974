import { dataProviderEnvVars } from "@/api/dataProviderEnvVars";

const environment = {
  namespaced: true,
  state: () => ({
    APIUrl: "",
    SiteTitle: "",
    LoginType: "form",
    Fetched: false,
  }),

  mutations: {
    setEnvironmentVars(state, payload) {
      state.APIUrl = payload.APIUrl;
      state.SiteTitle = payload.SiteTitle;
      state.LoginType = payload.LoginType;
      state.Fetched = payload.Fetched;
    },
    clearEnvironmentVars(state) {
      state.APIUrl = "";
      state.SiteTitle = "";
      state.LoginType = "";
      state.Fetched = false;
    },
  },

  actions: {
    async FetchEnvironmentVars({ commit }) {
      try {
        let response = await dataProviderEnvVars.getVars();

        let payload = {
          APIUrl: response.VUE_APP_API_BASE_URL,
          SiteTitle: response.VUE_APP_SITE_TITLE,
          LoginType: response.VUE_APP_LOGIN_TYPE,
          Fetched: true,
        };
        commit("setEnvironmentVars", payload);

        return response;
      } catch (err) {
        throw err.message;
      }
    },
  },

  getters: {
    APIUrl(state) {
      return state.APIUrl;
    },
    SiteTitle(state) {
      return state.SiteTitle;
    },
    LoginType(state) {
      return state.LoginType;
    },
    Fetched(state) {
      return state.Fetched;
    },
  },
};

export default environment;
