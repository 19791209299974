import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import login from "@/store/modules/login";
import register from "@/store/modules/register";
import contact from "@/store/modules/contact";
import upload from "@/store/modules/upload";
import environment from "@/store/modules/environment";

let modules = {
  environment,
  login,
  register,
  contact,
  upload,
};

// Only persist login state
let plugins = [
  createPersistedState({
    paths: [
      "login.ZipCode",
      "login.CarrierID",
      "login.CarrierPortalUserID",
      "login.AddressLine1",
      "login.City",
      "login.CompanyName",
      "login.EmailAddress",
      "login.FaxNumber",
      "login.FirstName",
      "login.LastName",
      "login.JobTitle",
      "login.MCNumber",
      "login.PhoneNumber",
      "login.State",
      "login.Username",
      "login.authed",
      "login.token",
      "login.refreshToken",
      "login.refreshTokenAD",
    ],
  }),
];

// Create a new store instance.
let store = createStore({
  modules,
  plugins,
});

export { store, modules, plugins };
