import axiosConfig from "@/api/axiosConfig.js";

const dataProviderLogin = {
  async login(email, password) {
    let result = await axiosConfig.post("/webauth/signin", {
      EmailAddress: email,
      Password: password,
    });

    return result.data;
  },
};
export { dataProviderLogin };
