import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import { store } from "./store/store.js";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ColumnGroup from "primevue/columngroup";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
const app = createApp(App);
import "./assets/css/tailwind.css";
import "./assets/css/app.scss";
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("InputText", InputText);
app.component("Button", Button);
app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(PerfectScrollbar);
app.mount("#app");
