import axios from "axios";

const dataProviderEnvVars = {
  async getVars() {
    //let result = await axios.get("/config/env.json");
    let result = await axios.get("/config/env.json");// remove this comment and comment above line when bundling application for specific directory

    return result.data;
  },
};

export { dataProviderEnvVars };
