<template>
  <Headerbar />
  <main>
    <div class="container mx-auto">
      <router-view />
    </div>
  </main>
  <Footerbar />
</template>

<script>
import Headerbar from "@/components/common/Headerbar.vue";
import Footerbar from "@/components/common/Footerbar.vue";
export default {
  name: "AppLayout",
  components: {
    Headerbar,
    Footerbar,
  },
};
</script>
